import React from "react";
import { Form, Button, TextArea } from "semantic-ui-react";
import { useSetAtom } from "jotai";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NMService from "../../services/nm.service";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import { licenseMgmtActions, licenseMgmtAtom } from "../../store/licenseManagment";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useFetchAllLicenses } from "../../hooks/useFetchAllLicenses";

const addLicenseFormSchema = yup.object().shape({
    license: yup
        .string()
        .test("license", function (value) {
            try {
                value
                    .split("\n")
                    .map((e) => e.trim())
                    .filter((e) => e !== "");
            } catch {
                return this.createError({
                    path: this.path,
                    message: "Invalid format",
                });
            }
            return true;
        })
        .required("License is required."),
});

const AddLicense = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const dispatch = useSetAtom(licenseMgmtAtom);

    const formOptions = {
        resolver: yupResolver(addLicenseFormSchema),
    };

    const { refetch } = useFetchAllLicenses({});

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm(formOptions);

    const onSubmit = async (values) => {
        const license = values.license
            .split("\n")
            .map((e) => e.trim())
            .filter((e) => e !== "");
        await NMService.postLicense(license)
            .then(() =>
                addGlobalMessage({
                    header: "License successfully added",
                    content: "New license successfully added",
                    type: "positive",
                })
            )
            .then(() => {
                dispatch({ type: licenseMgmtActions.RESET });
                refetch();
            })
            .catch((e) => null);
    };

    return (
        <Form className="basic segment" onSubmit={handleSubmit(onSubmit)}>
            <Form.Field error={Boolean(errors.license)}>
                <label>Enter new license</label>
                <Controller
                    name="license"
                    control={control}
                    render={({ field: { ref, ...field } }) => <TextArea {...field} rows={5} />}
                />
                {errors.name && <ErrorMessage>{errors.license.message}</ErrorMessage>}
            </Form.Field>
            <Form.Field>
                <Button size="small" primary type="submit">
                    Add
                </Button>
                <Button
                    size="small"
                    type="button"
                    onClick={() => dispatch({ type: licenseMgmtActions.CLOSE_FORM })}
                >
                    Cancel
                </Button>
            </Form.Field>
        </Form>
    );
};

export default AddLicense;
