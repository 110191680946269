import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useFetchAllLicenses } from "../../hooks/useFetchAllLicenses";
import { licenseMgmtActions, licenseMgmtAtom } from "../../store/licenseManagment";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import AddLicense from "./AddLicense";
import LicenseInfoSegment from "./LicenseInfoSegment";
import LicenseTable from "./LicenseTable";
import NMService from "../../services/nm.service";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import UsedLicenseTable from "./UsedLicenseTable";

const LicenseSegment = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const { showType, selectedLicense } = useAtomValue(licenseMgmtAtom);
    const [, dispatch] = useAtom(licenseMgmtAtom);

    const {
        data,
        isLoading,
        isFetching,
        refetch
    } = useFetchAllLicenses({
        options: {
            refetchOnMount: 'always'
        }
    });

    const onDeleteLicense = async () => {
        const uuid = selectedLicense?.uuid;

        if (!uuid) {
            addGlobalMessage({
                header: "License deletion failed",
                content: "License UUID is missing. Please select a valid license to delete.",
                type: "negative",
            });
        }
        await NMService.deleteLicense(selectedLicense.uuid)
            .then(() =>
                addGlobalMessage({
                    header: "License was deleted!",
                    content: `License with UUID ${uuid} was successfully deleted.`,
                    type: "positive",
                }))
            .then(() => {
                dispatch({ type: licenseMgmtActions.RESET })
                refetch()
            })
            .catch(() => null);
    };

    return (isLoading || isFetching ? null :
        <>
            <UsedLicenseTable />
            <LicenseTable data={data} />
            {(showType === licenseMgmtActions.SHOW_LICENSE_FORM) && <AddLicense />}
            {(showType === licenseMgmtActions.SHOW_LICENSE_INFO) && <LicenseInfoSegment />}
            {showType !== licenseMgmtActions.SHOW_DELETE_LICENSE ? null :
                <ConfirmationModal
                    open={true}
                    header="Delete license"
                    content="Are you sure you want to delete license"
                    onConfirm={onDeleteLicense}
                    onDismiss={() => dispatch({ type: licenseMgmtActions.RESET })}
                />}
        </>
    );
};

export default LicenseSegment;
