import { Table } from "semantic-ui-react"
import { useFetchAvailableLicenses } from "../../hooks/useFetchAvailableLicenses";
import { useFetchTotalLicenses } from "../../hooks/useFetchTotalLicenses";

const UsedLicenseTable = () => {
    const getLicenseType = (type) => {
        switch (type) {
            case "v1lpmn":
                return "5x9 Lightweight Node - Micro";
            case "v1lps":
                return "5x9 Lightweight Node - Small";
            case "v1lpm":
                return "5x9 Lightweight Node - Medium";
            case "v1lpl":
                return "5x9 Lightweight Node - Large";
            default:
                return type;
        }
    };

    const {
        data: availableLicenseList,
        isLoading: availableLicenseListIsLoading,
    } = useFetchAvailableLicenses({
        options: {
            refetchOnMount: 'always'
        }
    });

    const {
        data: totalLicenseList,
        isLoading: totalLicenseListIsLoading,
    } = useFetchTotalLicenses({
        options: {
            refetchOnMount: 'always'
        }
    });

    return (availableLicenseListIsLoading || totalLicenseListIsLoading ? null :
        <Table celled style={{ maxWidth: '60%' }}>
            <Table.Header>
                <Table.Row textAlign="center">
                    <Table.HeaderCell>License type</Table.HeaderCell>
                    <Table.HeaderCell>Remaining/Total quantity</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.entries(totalLicenseList).map((e, i) => (
                    <Table.Row key={i} textAlign="center">
                        <Table.Cell>{getLicenseType(e[0])}</Table.Cell>
                        <Table.Cell>
                            {availableLicenseList[e[0]]} / {e[1]}
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}

export default UsedLicenseTable;